import React from 'react';
import DatePicker from 'react-datepicker';

export interface IDatePickerFilterProps {
    startDate: Date | null;
    endDate: Date | null;
    handleDateChange(dateToChange: string, newDate: Date | null): void;
    fromLabel: string;
    toLabel: string;
}

class DatePickerFilter extends React.PureComponent<IDatePickerFilterProps> {
    private _startDateFieldId = 'start-date-picker-id';
    private _endDateFieldId = 'end-date-picker-id';

    constructor(props: IDatePickerFilterProps) {
        super(props);
    }

    private _renderDatePicker = () => {
        return (
            <div className='date-picker-wrapper'>
                <div className='date-picker-wrapper__start-date'>
                    <div className='date-picker-wrapper__start-date-text'>{this.props.fromLabel}</div>
                    <DatePicker
                        startDate={this.props.startDate}
                        selected={this.props.startDate}
                        onChange={date => this.props.handleDateChange('startDate', date)}
                        className={'date-picker-wrapper__start-date-picker'}
                        id={this._startDateFieldId}
                    />
                </div>
                <div className='date-picker-wrapper__end-date'>
                    <div className='date-picker-wrapper__end-date-text'>{this.props.toLabel}</div>
                    <DatePicker
                        startDate={this.props.endDate}
                        selected={this.props.endDate}
                        onChange={date => this.props.handleDateChange('endDate', date)}
                        className={'date-picker-wrapper__end-date-picker'}
                        id={this._endDateFieldId}
                    />
                </div>
            </div>
        );
    };

    public render(): JSX.Element | null {
        return this._renderDatePicker();
    }
}

export default DatePickerFilter;
